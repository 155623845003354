import * as Sentry from '@sentry/vue';
import { App } from 'vue';
import { Router } from 'vue-router';

export function initialiseSentry(app: App<Element>, router: Router) {
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      app,
      environment:
        import.meta.env.VITE_SENTRY_ENV ??
        import.meta.env.VITE_ENV ??
        import.meta.env.MODE,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      release: import.meta.env.VITE_RELEASE_VERSION,
      attachStacktrace: true,
      trackComponents: true,
      logErrors: true,

      integrations: [
        Sentry.breadcrumbsIntegration(),
        Sentry.browserTracingIntegration({ router }),
        // User Feedback
        Sentry.feedbackIntegration({
          colorScheme: 'light',
          showName: false,
          showEmail: false,
          autoInject: false,
        }),
        Sentry.functionToStringIntegration(),
        // Sessions Replay
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
        // Profiling
        Sentry.browserProfilingIntegration(),
      ],

      tracePropagationTargets: [
        // Track local development if needed
        'localhost',

        // Track requests to / from any of our domains
        /^.*\.vespertool\.com/,
      ],

      tracesSampleRate: parseFloat(
        import.meta.env.VITE_SENTRY_SAMPLE_RATE ?? 1.0
      ),

      // Session Replay - by default, this is disabled due to the limit quota we have available.
      replaysSessionSampleRate: parseFloat(
        import.meta.env.VITE_SENTRY_SESSION_REPLAY_SAMPLE_RATE ?? 0.0
      ),
      replaysOnErrorSampleRate: parseFloat(
        import.meta.env.VITE_SENTRY_SESSION_REPLAY_ERROR_SAMPLE_RATE ?? 0.0
      ),

      profilesSampleRate: parseFloat(
        import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE ?? 0.0
      ),
    });
  }
}
