<template>
  <div class="mb-5 px-6">
    <div class="rounded-lg bg-slate-50 p-4">
      <CompanyFactoryBanner
        v-if="deal || showProductOrigin"
        :listing="listing"
        :deal="deal"
      />
      <hr v-if="showProductOrigin" class="my-4 h-px bg-slate-200" />
      <!-- update when we have main spec inside the product object-->
      <div class="grid grid-cols-3 gap-4">
        <ListingInfoCardBlock
          :tag="
            $t(
              `product.main-spec.${productStore.findById(listing.productId)?.slug}`
            )
          "
          :value="Object.entries(listing.specifications)[0]?.[1][0]"
        />
        <ListingInfoCardBlock
          :tag="$t('listing-card.age')"
          :value="listing.specifications['Product age']?.[0]"
        />
      </div>
      <hr class="my-4 h-px bg-slate-200" />
      <div class="grid grid-cols-3 gap-4">
        <template
          v-for="(options, label) in filteredSpecifications"
          :key="label"
        >
          <ListingInfoCardBlock
            :tag="String(label)"
            :value="options.join(', ')"
          />
        </template>
      </div>
      <hr class="my-4 h-px bg-slate-200" />
      <div class="grid grid-cols-3 gap-4">
        <ListingInfoCardBlock
          :tag="$t('listing.shipment.incoterm')"
          :value="listing.shipment.incoterm"
        />
        <ListingInfoCardBlock
          :tag="$t('listing.shipment.delivery-location')"
          :value="
            shipmentStore.getLocationById(listing.shipment.locationId)?.name ??
            ''
          "
        />
        <ListingInfoCardBlock
          :tag="$t('listing.shipment.latest-delivery')"
          :value="$format(listing.shipment, 'delivery')"
        />
      </div>
      <template v-if="paymentTermDuration || deal">
        <hr class="my-4 h-px bg-slate-200" />
        <div class="grid grid-cols-3 gap-4">
          <ListingInfoCardBlock
            v-if="paymentTermDuration"
            :tag="$t('payment-term.title')"
            :value="
              $t('payment-term.duration-short', {
                duration: paymentTermDuration,
              })
            "
          />
          <ListingInfoCardBlock
            v-else
            :tag="$t('payment-term.title')"
            :value="$t('payment-term.pre-agreed-duration')"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import CompanyFactoryBanner from '@/components/CompanyFactoryBanner.vue';
import ListingInfoCardBlock from '@/components/ListingInfoCardBlock.vue';
import {
  useCounterParty,
  usePaymentTermDuration,
  useShowProductOrigin,
} from '@/composables';
import { useProductStore, useShipmentStore } from '@/stores';
import { EnrichedListingPreview, NegotiationPreview } from '@/types';
import { ReadCompanyDeal } from '@/types/deal';
import { computed } from 'vue';

const shipmentStore = useShipmentStore();
const productStore = useProductStore();

const props = defineProps<{
  listing: EnrichedListingPreview;
  negotiation?: NegotiationPreview;
  deal?: ReadCompanyDeal;
}>();

const counterParty = useCounterParty(props.listing, props.negotiation);
const paymentTermDuration = computed(() => {
  return (
    props.deal?.paymentTermDuration ??
    usePaymentTermDuration(props.listing, counterParty.value)
  );
});

const showProductOrigin = useShowProductOrigin(props.listing);

const filteredSpecifications = computed(() =>
  Object.fromEntries(Object.entries(props.listing.specifications).slice(2))
);
</script>
