import { Api } from '@/api/types';
import { DefaultPaymentTerm, PaymentTerm as PaymentTermType } from '@/types';

export class PaymentTerm {
  public constructor(protected readonly client: Api) {}

  public list(): Promise<Record<number, PaymentTermType>> {
    return this.client.get(`/customer/payment-terms`);
  }

  public findDefault(company: { id: number }): Promise<DefaultPaymentTerm> {
    return this.client.get(`/customer/${company.id}/default-payment-term`);
  }
}
