import { Api, Stat } from '@/api/types';

type MetricCache = { [key: string]: { [key: string]: number[] } };

export class StatsApi {
  public cache: MetricCache = {};
  public buffer: Stat[] = [];

  public constructor(protected readonly client: Api) {}

  public async flush(): Promise<void> {
    if (this.buffer.length === 0) {
      return;
    }

    const payload = this.buffer;
    this.buffer = [];

    try {
      await this.client.post(`/stats`, payload);
    } catch (e) {
      this.buffer.push(...payload);
      throw e;
    }
  }

  public async clear(): Promise<void> {
    try {
      await this.flush();
    } finally {
      this.buffer = [];
    }
  }

  public collectListingOpened(listingId: number): void {
    this.buffer.push({
      activity: 'OPENED',
      subject: 'LISTING',
      subjectId: listingId,
    });
  }

  public collectListingClosed(listingId: number): void {
    this.buffer.push({
      activity: 'CLOSED',
      subject: 'LISTING',
      subjectId: listingId,
    });
  }

  public collectListingShown(listingId: number): void {
    this.pushCached({
      activity: 'SHOWN',
      subject: 'LISTING',
      subjectId: listingId,
    });
  }

  /**
   * For some of the metrics we don't want to push several times, for that this will avoid sending the same metric more
   * than once
   */
  private pushCached(stat: Stat) {
    if (this.cache[stat.subject] === undefined) {
      // add default empty subject metrics
      this.cache[stat.subject] = {};
    }

    if (this.cache[stat.subject][stat.activity] === undefined) {
      // add default empty array when it is missing
      this.cache[stat.subject][stat.activity] = [];
    }

    if (this.cache[stat.subject][stat.activity].includes(stat.subjectId)) {
      // if the metric was already cache then to not push again
      return;
    }

    // cache the metric and push to the buffer
    this.cache[stat.subject][stat.activity].push(stat.subjectId);
    this.buffer.push(stat);
  }
}
