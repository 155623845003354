import { ListingPreview } from '@/types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePanelCreateListingStore = defineStore(
  'panelCreateListing',
  () => {
    const panelIsOpen = ref(false);
    const duplicateData = ref<ListingPreview | null>(null);

    const openPanel = (data: ListingPreview | null = null) => {
      duplicateData.value = data;
      panelIsOpen.value = true;
    };

    const closePanel = () => {
      duplicateData.value = null;
      panelIsOpen.value = false;
    };

    return {
      closePanel,
      duplicateData,
      openPanel,
      panelIsOpen,
    };
  }
);
