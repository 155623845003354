import generalDealCreatedHandlers from '@/notifications/general/general-deal-created-handlers';
import generalListingCanceledHandlers from '@/notifications/general/general-listing-canceled-handlers';
import generalListingCompletedHandlers from '@/notifications/general/general-listing-completed-handlers';
import generalListingCreatedHandlers from '@/notifications/general/general-listing-created-handlers';
import generalReloadStoreHandler from '@/notifications/general/general-reload-store-handler';
import { EventHandler } from '@/types/notification';

export const generalEventsHandlers: Record<string, EventHandler[]> = {
  'Backoffice.ReloadStore': generalReloadStoreHandler,
  'Marketplace.DealCreated': generalDealCreatedHandlers,
  'Marketplace.ListingCanceled': generalListingCanceledHandlers,
  'Marketplace.ListingCompleted': generalListingCompletedHandlers,
  'Marketplace.ListingCreated': generalListingCreatedHandlers,
};
