import { ReloadStore, StoreKey } from '@/notifications/types';
import {
  useApprovedFactoryStore,
  useApprovedTraderStore,
  useCompanyFactoryStore,
  useProductStore,
} from '@/stores';
import { captureException, withScope } from '@sentry/vue';

const storeHandlers: Record<StoreKey, () => void> = {
  product: () => useProductStore().load(),
  companyFactory: () => useCompanyFactoryStore().load(),
  approvedFactory: () => useApprovedFactoryStore().load(),
  approvedTrader: () => useApprovedTraderStore().load(),
};

const reloadStore = (event: ReloadStore) => {
  const handler = storeHandlers[event.storeKey];
  if (handler) {
    handler();
  } else {
    withScope((scope) => {
      scope.setLevel('warning');
      captureException(
        new Error('Failed to find handler for ReloadStore', {
          cause: new Error(`No handler found for ${event.storeKey}`),
        })
      );
    });
  }
};

export default [reloadStore];
