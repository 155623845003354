import { useApi } from '@/api';
import { PaymentTerm } from '@/types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePaymentTermStore = defineStore('paymentTerm', () => {
  const api = useApi();

  const paymentTerms = ref<Record<number, PaymentTerm | null>>({});

  async function load() {
    paymentTerms.value = await api.paymentTerm.list();
  }

  async function loadDefaultBy(company: { id: number }) {
    if (find(company) !== undefined) {
      return;
    }

    // We immediately set the value to null to prevent multiple requests
    paymentTerms.value[company.id] = null;

    try {
      const defaultPaymentTerm = await api.paymentTerm.findDefault(company);

      if (defaultPaymentTerm.duration === null) {
        return;
      }

      paymentTerms.value[company.id] = {
        counterParty: company.id,
        duration: defaultPaymentTerm.duration,
      };
    } catch {
      // We've encountered an unexpected error, so we need to delete the key to allow for a retry
      delete paymentTerms.value[company.id];
    }
  }

  /**
   * @returns PaymentTerm | null | undefined - The payment term for the counter-party; or
   * `null` if no default payment term exists; or
   * `undefined` if the payment term has not been loaded yet.
   */
  function find(counterParty: { id: number }): PaymentTerm | null | undefined {
    return paymentTerms.value[counterParty.id];
  }

  function clear() {
    paymentTerms.value = {};
  }

  return {
    clear,
    find,
    load,
    loadDefaultBy,
  };
});
