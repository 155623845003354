import { useApi } from '@/api';
import { disconnectPusher } from '@/broadcasts/pusher';
import { clearPolls } from '@/composables/use-polling-state';
import { useUserStore } from '@/stores';

export const useLogout = () => {
  const logout = async () => {
    const userStore = useUserStore();
    const api = useApi();
    await userStore.reset();
    await api.user.logout();
    clearPolls();
    disconnectPusher();
  };

  return {
    logout,
  };
};
