import { useApi } from '@/api';
import { useNotificationStore } from '@/stores';
import { Product } from '@/types/product';
import { CategorisedProductSpec, ProductSpec } from '@/types/product-specs';
import { StorageSerializers, useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

export const useProductStore = defineStore('product', () => {
  const api = useApi();
  const route = useRoute();
  const products = ref<Product[]>([]);
  const activeProductId = useLocalStorage<number | null>(
    'active_product_id',
    null,
    { serializer: StorageSerializers.number }
  );
  const productSpecs = ref<{ [id: number]: CategorisedProductSpec }>({});

  async function load() {
    products.value = await api.product.list();

    if (product.value?.slug === route.params.productSlug) {
      return;
    }

    const fromSlug = findBySlug(route.params.productSlug);

    if (!fromSlug) {
      return;
    }

    setActiveProduct(fromSlug);
  }

  const product = computed(() => {
    return findById(activeProductId.value) ?? products.value[0];
  });

  const specsOf = async (product: Product): Promise<CategorisedProductSpec> => {
    if (!productSpecs.value[product.id]) {
      productSpecs.value[product.id] = await api.product.specsOf(product.id);
    }

    return productSpecs.value[product.id];
  };

  const mainSpecOf = async (product: Product): Promise<ProductSpec> => {
    const specs = await specsOf(product);
    const categories = Object.keys(specs) as (keyof CategorisedProductSpec)[];
    const mainCategory = categories[0];
    const categorySpecs = specs[mainCategory];

    return categorySpecs[0];
  };

  function setActiveProduct(newProduct: Product) {
    useNotificationStore().removeActivityIndicator(newProduct.id);

    if (activeProductId.value === newProduct.id) {
      return;
    }

    activeProductId.value = newProduct.id;
  }

  const findById = (id: number | null): Product | undefined => {
    return products.value.find((product) => product.id === id);
  };

  const findBySlug = (slug: string | string[] | null): Product | undefined => {
    return products.value.find((product) => product.slug === slug);
  };

  return {
    load,
    product,
    products,
    specsOf,
    mainSpecOf,
    setActiveProduct,
    findById,
    findBySlug,
  };
});
